import React from 'react';
import { motion } from 'framer-motion';
import { BarChart2, DollarSign, Building, Image, Briefcase, Scale, Brain } from 'lucide-react';

const FeatureCard = ({ title, description }) => (
  <motion.div
    className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl shadow-2xl overflow-hidden"
    whileHover={{ scale: 1.05, boxShadow: "0 8px 30px rgba(0,255,170,0.2)" }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="p-6 border-b border-green-400/30">
      <h3 className="text-green-400 text-2xl font-bold tracking-wide">{title}</h3>
    </div>
    <div className="p-6">
      <p className="text-gray-300 leading-relaxed">{description}</p>
    </div>
  </motion.div>
);

const PluginCategory = ({ title, children }) => (
  <div className="mb-8">
    <h3 className="text-green-400 text-2xl font-bold mb-4">{title}</h3>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {children}
    </div>
  </div>
);

const PluginItem = ({ name, icon: Icon }) => (
  <motion.div
    className="bg-gray-800 rounded-xl p-4 flex items-center space-x-4"
    whileHover={{ scale: 1.05, boxShadow: "0 4px 20px rgba(0,255,170,0.2)" }}
  >
    <div className="bg-green-500 p-2 rounded-full">
      <Icon size={24} className="text-gray-900" />
    </div>
    <span className="text-gray-200 font-semibold">{name}</span>
  </motion.div>
);

function App() {
  const features = [
    {
      title: "Decentralized Web3 LLM",
      description: "Harness the power of the world's first decentralized Large Language Model built on Web3, revolutionizing finance, real estate, and corporate management."
    },
    {
      title: "Comprehensive Financial Integration",
      description: "Seamlessly connect with trading platforms, banks, and analytics tools for unparalleled financial insights and management capabilities."
    },
    {
      title: "Advanced GenAI Suite",
      description: "Unlock creativity and efficiency with cutting-edge generative AI tools for text, image, speech, and video production."
    },
    {
      title: "Intelligent Corporate Management",
      description: "Empower your organization with AI-driven CEO and CFO assistants, optimizing decision-making and financial strategies."
    },
    {
      title: "Decentralized Hedge Fund",
      description: "Participate in TickLab.IO, the world's first decentralized hedge fund, leveraging collective intelligence and blockchain technology."
    },
    {
      title: "Legal and Real Estate AI",
      description: "Navigate complex legal landscapes and real estate markets with specialized AI tools designed for analysis and decision support."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-950 text-white p-12 font-sans">
      <motion.h1 
        className="text-green-400 text-6xl font-extrabold text-center mb-4 tracking-tighter"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        E.D.I.T.H
      </motion.h1>
      <motion.p
        className="text-gray-400 text-2xl text-center mb-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        World's First Decentralized LLM Built on top of Web3 Applications
      </motion.p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 max-w-7xl mx-auto mb-20">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
      
      <motion.div
        className="mb-20"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <h2 className="text-green-400 text-4xl font-bold mb-8 text-center">Integrated Ecosystem</h2>
        <p className="text-gray-400 text-xl mb-12 text-center max-w-3xl mx-auto">
          Explore E.D.I.T.H's powerful ecosystem of integrated plugins, enhancing your capabilities across finance, real estate, and corporate management.
        </p>
        <div className="max-w-6xl mx-auto">
          <PluginCategory title="Financial Tools">
            <PluginItem name="TradingView Analysis" icon={BarChart2} />
            <PluginItem name="Binance" icon={DollarSign} />
            <PluginItem name="TD Ameritrade" icon={DollarSign} />
            <PluginItem name="Alpaca" icon={DollarSign} />
            <PluginItem name="TickLab.IO Hedge Fund" icon={BarChart2} />

          </PluginCategory>
          <PluginCategory title="Real Estate & Banking">
            <PluginItem name="Real Estate Websites" icon={Building} />
            <PluginItem name="Account Analysis" icon={BarChart2} />
            <PluginItem name="Budget Management" icon={DollarSign} />
            <PluginItem name="Direct Bank Integration" icon={Building} />
          </PluginCategory>
          <PluginCategory title="GenAI Suite">
            <PluginItem name="Text to Image" icon={Image} />
            <PluginItem name="Text to Text" icon={Image} />
            <PluginItem name="Text to Speech" icon={Image} />
            <PluginItem name="Text to Video" icon={Image} />
          </PluginCategory>
          <PluginCategory title="Corporate AI Agent">
            <PluginItem name="Law Corp AI Agent" icon={Scale} />
            <PluginItem name="AI CEO Agent" icon={Briefcase} />
            <PluginItem name="AI CFO Agent" icon={Brain} />
            <PluginItem name="AI Customer Service Agent" icon={Brain} />
            <PluginItem name="AI Marketing Agent" icon={Brain} />
          </PluginCategory>
        </div>
      </motion.div>

      <motion.div
        className="text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <h2 className="text-green-400 text-4xl font-bold mb-4">Coming Soon</h2>
        <p className="text-gray-400 text-xl mb-8">Join the waitlist to be notified when we launch</p>
        <motion.button
          className="bg-green-500 text-white px-8 py-4 rounded-full font-semibold text-lg tracking-wide hover:bg-green-400 transition duration-300"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Join Waitlist
        </motion.button>
      </motion.div>
    </div>
  );
}

export default App;